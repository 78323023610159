<template>
  <div id="container" v-if="fetch_data">
    <section id="shop">
      <div class="wrap">
        <div class="cts_box mb20">
          <h2 class="main_ttl"><span><router-link :to="{ name: 'ShoppingArticles'}">ショップ</router-link></span></h2>
          <ShopMenu :fetch_data="fetch_data"/>
        </div>

        <div class="cts_box mb20">
          <h2 class="min_ttl">複眼経済塾オンラインショップ</h2>
          <p>
            当社書籍やオリジナルノートを販売しております。<br>
            塾生の方はログインしてご利用ください。ポイントがご利用いただけます。<br>
            一般の方もゲスト購入が可能です。<br>
            ショップご利用の際は必ず<router-link to="/shop_info/">ご利用ガイド</router-link>のご確認をお願いいたします。
          </p>
        </div>

        <div class="cts_box search" v-if="false">
          <p class="bold blue">チェックを入れた内容で表示を絞り込みます。</p>
          <form action="">
            <div class="radio">
              <input id="radio01" type="radio" name="category" v-model="category" v-on:change="pageChanged(1)" value="">
              <label for="radio01">すべて表示</label>
              <template v-for="m in fetch_data.categories">
                <input v-bind:id="'radio-' + m.id" type="radio" name="category" v-model="category" v-bind:value="m.id"
                       :key="'cate' + m.id" v-on:change="pageChanged(1)">
                <label v-bind:for="'radio-' + m.id" :key="'name' + m.id">{{ m.name }}</label>
              </template>
            </div>
          </form>
        </div>

        <div class="cts_box">
          <h2 class="sub_ttl" v-if="false"><span>すべての商品</span></h2>
          <p class="lead_txt" v-if="false"><span class="hit_num">{{ delimited(fetch_data.pagination.total_count) }}</span>件の商品が登録されています。
          </p>
          <div class="sort flex" style="">
            <p>並び替え</p>
            <select v-model="order" v-on:change="pageChanged(1)">
              <option value="">オススメ順</option>
              <option value="category_asc">ジャンル順</option>
              <option value="price_asc">価格の安い順番</option>
              <option value="price_desc">価格の高い順番</option>
            </select>
          </div>

          <div class="shop_item_list flex">
            <div class="shop_item flex" v-bind:class="{'no-stock': article.status === 'status_no_stock'}" v-for="article in fetch_data.articles" v-bind:key="article.id">
              <div class="images">
                <a href="">
                  <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                    <img v-bind:src="article.image_files[0]" alt="">
                  </router-link>
                </a>
              </div>
              <div class="item_detail">
                <h3>
                  <span class="new" v-if="article.new_icon">NEW</span>
                  <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                    {{ article.title }}
                  </router-link>
                </h3>
                <p class="price">¥ {{ delimited(article.price) }}（税込）</p>
                <template v-if="false">
                  <span class="tag" v-if="article.point_usable">ポイント払い可能</span>
                  <span class="tag" v-else>ポイント払い不可</span>
                </template>
                <div class="item_txt" style="white-space: break-spaces" v-if="false">
                  {{ article.description }}
                  <template v-if="article.add_info">
                    <br>
                    {{ article.add_info }}
                  </template>
                </div>
                <div class="add_cart">
                  <a href="javascript:void(0)" v-on:click="addCart(article)" class="submit light cart">カートに入れる</a>
                </div>
              </div>
            </div>
          </div>

          <!--1P20商品 pager-->
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged"/>
          <!--//pager-->
        </div>
        <div class="button_wrap" v-if="isUserSignedIn()">
          <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit arrow back light skeleton large">
            マイページに戻る
          </router-link>
        </div>
        <div class="button_wrap" v-else>
          <router-link to="/" class="submit arrow back light skeleton large">
            トップページに戻る
          </router-link>
        </div>
      </div>
    </section>
    <CartModal :article="article" v-if="article" v-on:canceled="article=null"/>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import Pagination from '@/components/Pagination.vue'
import ShopMenu from '@/views/shop/_shop_menu'
import CartModal from '@/views/shop/_cart_modal'

export default {
  mixins: [Libraries],
  components: {
    Pagination,
    ShopMenu,
    CartModal
  },
  data() {
    return this.initialize()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    initialize() {
      return {
        fetch_data: null,
        page: this.$route.query.page == null ? 1 : Number(this.$route.query.page),
        category: this.$route.query.category == null ? '' : this.$route.query.category,
        order: this.$route.query.order == null ? '' : this.$route.query.order,
        article: null
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/articles.json`, {
            params: {
              page: this.page,
              category: this.category,
              order: this.order,
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    pageChanged(page) {
      let query = {}
      if (this.order !== '') query.order = this.order
      if (this.category !== '') query.category = this.category
      if (page !== 1) query.page = page

      this.$router.push({
        name: 'ShoppingArticles',
        params: this.$route.params,
        query: query
      })
      //}
    },
    addCart(article) {
      this.axios
          .post(`${this.env.api_base_url}shop/update_article.json`, {
            id: article.id,
            count: 1
          })
          .then(response => {
            this.fetch_data.shopping_count = response.data.shopping_count
            this.fetch_data.total_points = response.data.total_points
            this.fetch_data.usable_shop_coupons = response.data.usable_shop_coupons
            this.article = article
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>
